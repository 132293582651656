import autoBind from 'react-autobind';
import { Field, reduxForm } from 'redux-form';
import React, { Component } from 'react';
import { scrollToAnchor } from 'src/utils/helpers';
import TextField from 'material-ui/TextField';
import moment from 'moment';
import { renderInput } from 'src/components/misc/redux-form-helpers';
import { Tab, Tabs } from 'material-ui/Tabs';
import SwipeableViews from 'react-swipeable-views';
import { isBlank } from 'src/components/misc/validations';

class RatingAnswerForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const { review, t, handleSubmit, selectedSliderIndex } = this.props;
        return (
            <div>
                <div>
                    <Tabs onChange={value => this.props.setSliderIndex(value)} value={selectedSliderIndex}>
                        <Tab label={t('administration.task_ratings.review_infos')} value={0} />
                        <Tab label={t('administration.task_ratings.task_infos')} value={1} />
                    </Tabs>
                </div>
                <SwipeableViews
                    index={selectedSliderIndex}
                    className="voffset"
                    onChangeIndex={value => this.props.setSliderIndex(value)}>
                    <div>
                        <div className="col-md-12">
                            <label>{t('administration.task_ratings.customer_comment')}</label>
                            <TextField
                                name="comment"
                                multiLine={true}
                                fullWidth={true}
                                disabled={true}
                                rows={3}
                                value={review.comment}
                            />
                            <form role="form" className="voffset" onSubmit={handleSubmit}>
                                <label>{t('administration.task_ratings.operator_answer')}</label>
                                <Field
                                    id="operatorAnswer"
                                    name="operatorAnswer"
                                    disabled={review.answerStatus !== 'EMPTY'}
                                    label={t('administration.task_ratings.operator_answer')}
                                    component={renderInput}
                                    multiline
                                    rows={3}
                                    rowsMax={3}
                                    t={t}
                                />
                            </form>
                        </div>
                        <div className="col-md-12 voffset">
                            <p>{t('administration.task_ratings.operator_answer_guideline')}</p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        {this.getTaskDetails(review)}
                        {this.getReviewInfo(review.entries)}
                    </div>
                </SwipeableViews>
            </div>
        );
    }

    getReviewInfo(entries) {
        const { t } = this.props;
        return (
            <table className="table table-striped">
                <thead>
                    <tr>
                        <td>{t('administration.task_ratings.category')}</td>
                        <td>{t('administration.task_ratings.rating_stars_headline')}</td>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(entries).map(([i, val]) => (
                        <tr key={i}>
                            <td>{i}</td>
                            <td>{val}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    getTaskDetails(review) {
        const { task, customer } = review;
        const { t } = this.props;
        return (
            <div>
                <label>{t('administration.task_ratings.task_infos')}</label>
                <table className="table table-striped">
                    <tbody>
                        <tr>
                            <td>AuftragsNr.:</td>
                            <td>{task.identifier}</td>
                        </tr>
                        <tr>
                            <td>Datum:</td>
                            <td>
                                {moment(task.taskFrom.time).format()} - {task.bookingIdentifier}
                            </td>
                        </tr>
                        <tr>
                            <td>Kunde:</td>
                            <td>{customer.contactData.email}</td>
                        </tr>
                        <tr>
                            <td width="15%">Von:</td>
                            <td>{task.taskFrom.location.address}</td>
                        </tr>

                        <tr>
                            <td>Nach:</td>
                            <td>{task.taskTo.location.address}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    errors.emptyAnswer = t('error_missing.fill_in');
    if (isBlank(values.operatorAnswer)) errors.operatorAnswer = t('error_missing.fill_in');
    if (!isBlank(values.operatorAnswer) && values.operatorAnswer.length > 2000)
        errors.operatorAnswer = t('error_missing.fill_in_operator_answer_max_length');
    return errors;
};

const onSubmitFail = (errors, dispatch) => {
    scrollToAnchor('TOP');
};

export default reduxForm({
    form: 'ratingAnswer',
    onSubmitFail,
    validate,
    asyncBlurFields: ['operatorAnswer'],
})(RatingAnswerForm);
