import React, { Component } from 'react';
import autoBind from 'react-autobind/src/autoBind';
import { getOwnCompany } from 'src/selectors/bus-companies';
import CustomerAdministrationNewCustomerForm from 'src/components/administration/customer/components/customer-administartion-new-customer-form';
import { connect } from 'react-redux';
import { asyncRequest, Entities, fetchByCompanyId, fetchById, patch, save, TOMCAT_URL } from 'src/common';
import HttpStatus from 'http-status-codes';
import { addNotification } from 'src/actions/notifications';

class CustomerAdministrationNewCustomerModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    render() {
        const { customerGroups, t } = this.props;
        return (
            <div>
                <CustomerAdministrationNewCustomerForm
                    t={t}
                    customerGroups={customerGroups}
                    onSubmit={this.handleSubmit}
                    ref={ref => (this.childForm = ref)}
                />
            </div>
        );
    }

    handleSubmit(data) {
        const { handleClose } = this.props;
        this.createNewCustomer(data);
        handleClose();
    }

    async createNewCustomer(data) {
        const { t, handleClose, fetchCustomers } = this.props;
        data.contactData = { ...data };
        try {
            await asyncRequest({
                method: 'POST',
                endpoint: `${TOMCAT_URL}api/${Entities.CUSTOMER.repository}/createNewCustomer`,
                body: {
                    ...data,
                },
            });
            handleClose();
            fetchCustomers();
        } catch (error) {
            if (error.status === HttpStatus.CONFLICT) {
                this.props.addNotification(
                    t('customer_details.duplicate_email'),
                    t('customer_details.duplicate_email_message'),
                    'error',
                    'tr',
                );
            }

            if (error.status === HttpStatus.BAD_REQUEST) {
                this.props.addNotification(
                    t('customer_details.missing_email'),
                    t('customer_details.missing_email_message'),
                    'error',
                    'tr',
                );
            }
        }
    }
}

const mapStateToProps = state => {
    return {
        selectedCompany: state.selectedCompany,
        ownCompany: getOwnCompany(state),
    };
};

export default connect(
    mapStateToProps,
    {
        fetchByCompanyId,
        fetchById,
        patch,
        save,
        addNotification,
    },
    null,
    { withRef: true },
)(CustomerAdministrationNewCustomerModal);
