import { Entities, isInteger, TOMCAT_URL } from 'src/common/index';
import MenuItem from 'material-ui/MenuItem';
import { Tab, Tabs } from 'material-ui/Tabs';
import moment from 'moment';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import SwipeableViews from 'react-swipeable-views';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { ImageCropperTypes } from 'src/components/images/image-cropper';
import {
    asyncCheckDuplicate,
    Location,
    renderCheckbox,
    renderGalleryImages,
    renderImageSelector,
    renderInput,
    renderMultiSelectCheckboxes,
    renderRichTextEditor,
    renderSelectField,
    validateLocation,
} from 'src/components/misc/redux-form-helpers';
import { isBlank } from 'src/components/misc/validations';
import { getIntegrationFieldReadOnlyText } from 'src/selectors/integrations';
import DatePicker from 'src/components/misc/DatePicker';
import { ReleaseMode } from 'src/utils/constants';
import { checkIfSold, getMenuItemsByArray, scrollToAnchor } from 'src/utils/helpers';
import RaisedButton from 'material-ui/RaisedButton';
import { setSliderIndex } from 'src/actions/form';

class BusEditForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const {
            brands,
            bus,
            busCompany,
            engines,
            categories,
            allEquipments,
            drivers,
            calculations,
            handleSubmit,
            selectedSliderIndex,
            t,
        } = this.props;

        const driverMenuItems = [<MenuItem key={-1} value="" primaryText="-" />];
        drivers.items.forEach((driver, index) => {
            driverMenuItems.push(
                <MenuItem
                    key={index}
                    value={driver['_links']['self']['href']}
                    primaryText={driver.contactData.firstName}
                />,
            );
        });

        const starsMenuItems = [<MenuItem key={-1} value="" primaryText="-" />];
        [1, 2, 3, 4, 5].forEach((star, index) => {
            starsMenuItems.push(
                <MenuItem
                    key={index}
                    value={star}
                    primaryText={
                        star === 1
                            ? t('star_rating.star_amount', { star: star })
                            : t('star_rating.stars_amount', { star: star })
                    }
                />,
            );
        });

        const calculationMenuItems = getMenuItemsByArray(calculations.items, 'name');
        const categoryMenuItems = getMenuItemsByArray(categories.items, 'name');
        const brandMenuItems = getMenuItemsByArray(brands.items, 'name');
        const engineMenuItems = getMenuItemsByArray(engines.items, 'name');
        return (
            <form className="form-horizontal" role="form" onSubmit={handleSubmit}>
                <div>
                    <Tabs onChange={value => this.props.setSliderIndex(value)} value={selectedSliderIndex}>
                        <Tab label={t('bus.basic_data')} value={0} />
                        <Tab label={t('bus.equipment')} value={1} />
                        <Tab label={t('bus.pictures')} value={2} />
                        {busCompany.entity.furtherBases && <Tab label={t('bus.further_bases')} value={3} />}
                    </Tabs>
                    <SwipeableViews
                        index={selectedSliderIndex}
                        onChangeIndex={value => this.props.setSliderIndex(value)}>
                        <div className="bus-view">
                            <div className="row">
                                <div className="col-md-6">
                                    <div>
                                        <Field
                                            name="avatar"
                                            t={t}
                                            component={renderImageSelector}
                                            type={ImageCropperTypes.BUS}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div id="topSection">
                                        <Field
                                            name="name"
                                            label={`${t('common_phrases.name')} *`}
                                            component={renderInput}
                                        />
                                        <Field
                                            name="displayName"
                                            label={t('bus.display_name')}
                                            component={renderInput}
                                        />
                                        {this.getVinField()}
                                        <Field
                                            name="plateNumber"
                                            label={t('bus.plate_number')}
                                            component={renderInput}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 voffset">
                                    <Field
                                        disabled={bus && bus.expired}
                                        title={
                                            bus && bus.expired
                                                ? t('bus.expired_car', {
                                                      date: moment(bus.expiry).format('DD.MM.YYYY'),
                                                  })
                                                : ''
                                        }
                                        name="active"
                                        label={t('bus.active_bookable')}
                                        component={renderCheckbox}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="registrationDate"
                                        label={`${t('bus.registrationDate')} *`}
                                        component={DatePicker}
                                    />
                                </div>
                            </div>
                            {busCompany.entity.virtual && (
                                <div className="row">
                                    <div className="col-md-12 voffset">
                                        <Field name="virtual" label={t('bus.virtual')} component={renderCheckbox} />
                                    </div>
                                </div>
                            )}
                            <br />
                            <div id="homeBase" className="row">
                                <div className="col-md-12">
                                    <Field name="homeBase" label={`${t('bus.home_base')} *`} component={Location} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-7">
                                    <Field
                                        name="regularDriver"
                                        label={t('bus.regular_driver')}
                                        component={renderSelectField}>
                                        {driverMenuItems}
                                    </Field>
                                    <Field name="releaseMode" label={t('bus.mode')} component={renderSelectField}>
                                        <MenuItem key={0} value={ReleaseMode.BLOCKED} primaryText={t('bus.blocked')} />
                                        <MenuItem key={1} value={ReleaseMode.RELEASED} primaryText={t('bus.free')} />
                                    </Field>
                                    <Field
                                        name="calculation"
                                        label={t('bus.calculation')}
                                        component={renderSelectField}>
                                        {calculationMenuItems}
                                    </Field>
                                    <Field
                                        name="category"
                                        label={t('common_phrases.category')}
                                        component={renderSelectField}>
                                        {categoryMenuItems}
                                    </Field>
                                    <Field name="type" label={t('common_phrases.type')} component={renderInput} />
                                    <Field
                                        name="stars"
                                        label={t('bus.stars_distance')}
                                        hintText={t('bus.stars_hint')}
                                        component={renderSelectField}>
                                        {starsMenuItems}
                                    </Field>
                                    <Field
                                        name="totalPermittedWeightInKg"
                                        label={t('bus.total_permitted_weight_in_kg')}
                                        component={renderInput}
                                        type="number"
                                    />
                                    <Field
                                        name="numberOfAxles"
                                        label={t('bus.number_of_axles')}
                                        component={renderInput}
                                        type="number"
                                    />
                                    <Field
                                        name="averageFuelConsumption"
                                        label={t('bus.average_fuel_consumption')}
                                        component={renderInput}
                                        type="number"
                                    />
                                </div>
                                <div id="seats" className="col-md-5">
                                    <Field
                                        name="seatsFacingFront"
                                        label={`${t('bus.seats_facing_front')} *`}
                                        component={renderInput}
                                        type="number"
                                    />
                                    <Field
                                        name="seatsFacingBack"
                                        label={t('bus.seats_facing_back')}
                                        component={renderInput}
                                        type="number"
                                    />
                                    <Field
                                        name="seatsForGuides"
                                        label={t('bus.seats_for_guides')}
                                        component={renderInput}
                                        type="number"
                                    />
                                    <Field
                                        name="restrictSeatsForGuides"
                                        label={t('bus.restrictSeatsForGuides')}
                                        component={renderCheckbox}
                                    />
                                    <Field name="brand" label={t('brands.brand')} component={renderSelectField}>
                                        {brandMenuItems}
                                    </Field>
                                    <Field name="engine" label={t('engines.euronorm')} component={renderSelectField}>
                                        {engineMenuItems}
                                    </Field>
                                    <Field name="order" label={t('bus.order')} component={renderInput} type="number" />
                                    <Field
                                        name="busHeightInCm"
                                        label={t('bus.height_in_cm')}
                                        component={renderInput}
                                        type="number"
                                    />
                                    <Field
                                        name="busLengthInCm"
                                        label={t('bus.length_in_cm')}
                                        component={renderInput}
                                        type="number"
                                    />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-md-12">
                                    <label>{t('common_phrases.description')}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <Field
                                        name="description"
                                        placeholder={t('common_phrases.description')}
                                        component={renderRichTextEditor}
                                        t={t}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="bus-view">
                            <Field name="equipments" component={renderMultiSelectCheckboxes} items={allEquipments} />
                        </div>
                        <div className="bus-view">
                            <Field
                                name="documents"
                                component={renderGalleryImages}
                                t={t}
                                type={ImageCropperTypes.BUS}
                            />
                        </div>
                        {busCompany.entity.furtherBases && (
                            <div className="bus-view">
                                <FieldArray name="furtherBases" t={t} component={renderFurtherBases} />
                            </div>
                        )}
                    </SwipeableViews>
                </div>
            </form>
        );
    }

    getVinField() {
        const { t } = this.props;
        const { isCreate, bus } = this.props;

        const isImported = !isCreate && bus !== undefined && bus.externalBusId != null;
        const isSold = !isCreate && checkIfSold(bus);

        const isDisabled = isSold || isImported;
        const tooltip = isImported ? getIntegrationFieldReadOnlyText(t) : isSold ? t('bus.car_sold') : '';

        return <Field disabled={isDisabled} title={tooltip} name="vin" label="VIN" component={renderInput} />;
    }
}

const renderFurtherBases = ({ fields, t }) => (
    <>
        <div className="row" style={{ margin: 0 }}>
            <div className="col-md-6">
                <div className="form-group">
                    <RaisedButton
                        secondary={true}
                        label={`+ ${t('bus.new_base')}`}
                        onClick={() => fields.splice(0, 0, undefined)}
                    />
                </div>
            </div>
        </div>

        {fields.map((furtherBase, index) => (
            <div key={index} className="row" style={{ margin: 0 }}>
                <div className="col-md-11">
                    <Field name={furtherBase} label={t('bus.home_base')} component={Location} />
                </div>
                <div className="col-md-1 voffset">
                    <button className="btn btn-sm" type="button" onClick={() => fields.remove(index)}>
                        <span className="glyphicon glyphicon-trash" />
                    </button>
                </div>
            </div>
        ))}
    </>
);

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (isBlank(values.name)) errors.name = t('error_missing.fill_in_name');

    if (!values.registrationDate) errors.registrationDate = t('error_missing.fill_in');

    if (isBlank(values.homeBase)) errors.homeBase = t('bus.fill_in_home_base');
    else if (validateLocation(values.homeBase)) errors.homeBase = t('bus.select_address');

    if (!isInteger(values.seatsFacingFront)) errors.seatsFacingFront = t('error_hint.error_integer');
    if (!isInteger(values.seatsFacingBack)) errors.seatsFacingBack = t('error_hint.error_integer');
    if (!isInteger(values.seatsForGuides)) errors.seatsForGuides = t('error_hint.error_integer');
    if (!isInteger(values.order)) errors.order = t('error_hint.error_integer');

    if (values.totalPermittedWeightInKg && !isInteger(values.totalPermittedWeightInKg))
        errors.totalPermittedWeightInKg = t('error_hint.error_integer');
    if (values.busHeightInCm && !isInteger(values.busHeightInCm)) errors.busHeightInCm = t('error_hint.error_integer');
    if (values.busLengthInCm && !isInteger(values.busLengthInCm)) errors.busLengthInCm = t('error_hint.error_integer');
    if (values.numberOfAxles && !isInteger(values.numberOfAxles)) errors.numberOfAxles = t('error_hint.error_integer');

    if (values.seatsFacingFront < 1) errors.seatsFacingFront = t('bus.error_at_least_one_seat');
    if (values.seatsFacingBack < 0) errors.seatsFacingBack = t('error_hint.error_negative');
    if (values.seatsForGuides < 0) errors.seatsForGuides = t('error_hint.error_negative');
    if (values.order < -100 || values.order > 100) errors.order = t('bus.error_order');

    if (values.totalPermittedWeightInKg < 0) errors.totalPermittedWeightInKg = t('error_hint.error_negative');
    if (values.busHeightInCm < 0) errors.busHeightInCm = t('error_hint.error_negative');
    if (values.busLengthInCm < 0) errors.busLengthInCm = t('error_hint.error_negative');
    if (values.numberOfAxles < 0) errors.numberOfAxles = t('error_hint.error_negative');

    errors.furtherBases = [];
    values.furtherBases.forEach((furtherBase, index) => {
        if (validateLocation(furtherBase)) errors.furtherBases[index] = t('bus.select_address');
    });

    return errors;
};

const asyncValidate = (values, dispatch, props, blurredField) => {
    const { t } = props;
    if (blurredField === 'name')
        return asyncCheckDuplicate(
            t,
            `${TOMCAT_URL}api/${Entities.BUS.repository}/search/findByNameAndCompanyId?name=${encodeURIComponent(
                values.name,
            )}&companyId=${props.selectedCompany}`,
            values.id,
            'name',
        );
    else if (blurredField === 'vin')
        return asyncCheckDuplicate(
            t,
            `${TOMCAT_URL}api/${Entities.BUS.repository}/search/findByVin?vin=${encodeURIComponent(values.vin)}`,
            values.id,
            'vin',
            t('bus.vin_allocated'),
        );
    else if (blurredField === undefined)
        return asyncCheckDuplicate(
            t,
            `${TOMCAT_URL}api/${Entities.BUS.repository}/search/findByNameAndCompanyId?name=${encodeURIComponent(
                values.name,
            )}&companyId=${props.selectedCompany}`,
            values.id,
            'name',
        ).then(() => {
            return asyncCheckDuplicate(
                t,
                `${TOMCAT_URL}api/${Entities.BUS.repository}/search/findByVin?vin=${encodeURIComponent(values.vin)}`,
                values.id,
                'vin',
                t('bus.vin_allocated'),
            );
        });

    return Promise.resolve();
};

const onSubmitFail = (errors, dispatch) => {
    let field;

    if (errors.name || errors.passengers || errors.price || errors.postProcessingTime) {
        dispatch(setSliderIndex(0));
        field = 'topSection';
    } else if (errors.homeBase) {
        dispatch(setSliderIndex(0));
        field = 'homeBase';
    } else if (errors.seatsFacingFront) {
        dispatch(setSliderIndex(0));
        field = 'seats';
    } else if (errors.furtherBases.length > 0) {
        dispatch(setSliderIndex(3));
    }

    // scroll to first error field
    if (field) scrollToAnchor(field);
};

export default reduxForm({
    form: 'busEdit',
    onSubmitFail,
    validate,
    asyncValidate,
    asyncBlurFields: ['name', 'vin'],
})(BusEditForm);
