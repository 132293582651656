import { Entities, Projection, TOMCAT_URL } from 'src/common/index';
import { apiRequest } from 'src/actions/api/api';
import { FAILURE_TASKS, REQUEST_TASKS, SUCCESS_FETCH_TASKS } from 'src/actions/tasks';

export const REQUEST_REVIEWS = 'REQUEST_REVIEWS';
export const SUCCESS_FETCH_REVIEWS = 'SUCCESS_FETCH_REVIEWS';
export const FAILURE_REVIEWS = 'FAILURE_REVIEWS';

export function fetchReviewsByCompany(busCompany, projection = Projection.DEFAULT) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${
                    Entities.REVIEW.repository
                }/search/findAllByCompanyId?companyId=${busCompany}${projection ? `&projection=${projection}` : ''}`,
                method: 'GET',
                secondaryActionTypes: [REQUEST_REVIEWS, SUCCESS_FETCH_REVIEWS, FAILURE_REVIEWS],
                reducerIndex: busCompany,
                schema: Entities.REVIEW.schema,
            }),
        );
    };
}

export function fetchReviewsByFilterAdmin(filter, projection = Projection.DEFAULT) {
    let fetchMethod = 'findAllAdmin';
    switch (filter) {
        case 'REVIEW_NEEDS_APPROVAL':
            fetchMethod = 'findAllInvisibleReviews';
            break;
        case 'ANSWER_NEEDS_APPROVAL':
            fetchMethod = 'findUnapprovedAnswers';
            break;
        default:
            fetchMethod = 'findAllAdmin';
    }

    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${
                    Entities.REVIEW.repository
                }/search/${fetchMethod}${projection ? `?projection=${projection}` : ''}`,
                method: 'GET',
                secondaryActionTypes: [REQUEST_REVIEWS, SUCCESS_FETCH_REVIEWS, FAILURE_REVIEWS],
                reducerIndex: 0,
                schema: Entities.REVIEW.schema,
            }),
        );
    };
}

export function fetchReviewsByFilter(busCompany, filter, projection = Projection.DEFAULT) {
    let fetchMethod = 'findOwnUnansweredReviews';
    switch (filter) {
        case 'MY_UNANSWERED_REVIEWS':
            fetchMethod = 'findOwnUnansweredReviews';
            break;
        default:
            fetchMethod = 'findAllByCompanyId';
    }
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${
                    Entities.REVIEW.repository
                }/search/${fetchMethod}?companyId=${busCompany}${projection ? `&projection=${projection}` : ''}`,
                method: 'GET',
                secondaryActionTypes: [REQUEST_REVIEWS, SUCCESS_FETCH_REVIEWS, FAILURE_REVIEWS],
                reducerIndex: busCompany,
                schema: Entities.REVIEW.schema,
            }),
        );
    };
}

export function patchApproveOperatorReview(review) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${Entities.REVIEW.repository}/approveOperatorReview?reviewId=${review.id}`,
                method: 'PATCH',
                secondaryActionTypes: [
                    `REQUEST_${Entities.REVIEW.action}`,
                    `SUCCESS_UPDATE_${Entities.REVIEW.action}`,
                    `FAILURE_${Entities.REVIEW.action}`,
                ],
                reducerIndex: 0,
                schema: Entities.REVIEW.schema,
                body: JSON.stringify({
                    ...review,
                }),
            }),
        );
    };
}

export function patchApproveOperatorAnswer(review) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${Entities.REVIEW.repository}/approveOperatorAnswer?reviewId=${review.id}`,
                method: 'PATCH',
                secondaryActionTypes: [
                    `REQUEST_${Entities.REVIEW.action}`,
                    `SUCCESS_UPDATE_${Entities.REVIEW.action}`,
                    `FAILURE_${Entities.REVIEW.action}`,
                ],
                reducerIndex: 0,
                schema: Entities.REVIEW.schema,
                body: JSON.stringify({
                    ...review,
                }),
            }),
        );
    };
}

export function patchOperatorAnswer(busCompany, review, projection = Projection.DEFAULT) {
    return dispatch => {
        return dispatch(
            apiRequest({
                endpoint: `${TOMCAT_URL}api/${Entities.REVIEW.repository}/submitOperatorAnswer?busCompany=${busCompany}&reviewId=${review.content.id}`,
                method: 'PATCH',
                secondaryActionTypes: [
                    `REQUEST_${Entities.REVIEW.action}`,
                    `SUCCESS_UPDATE_${Entities.REVIEW.action}`,
                    `FAILURE_${Entities.REVIEW.action}`,
                ],
                reducerIndex: busCompany,
                schema: Entities.REVIEW.schema,
                body: JSON.stringify({
                    operatorAnswer: review.content.operatorAnswer,
                }),
            }),
        );
    };
}
