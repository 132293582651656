import React, { Component } from 'react';
import autoBind from 'react-autobind/src/autoBind';
import TableHelper from 'src/utils/table-helper';
import { withTranslation } from 'react-i18next';
import composeModalActions from 'src/utils/modal-action-wrapper';
import { fetchReviewsByCompany } from 'src/actions/ratings';
import ErrorMessage from 'src/components/misc/error-message';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import RatingAnswerModal from 'src/components/administration/task-ratings/components/rating-answer-modal';
import { taskAdministrationFilterMappings } from 'src/components/taskadministration/task-administration';
import _ from 'lodash';

export const reviewAdministrationFilterMappings = t => ({
    MY_REVIEWS: {
        filter: 'MY_REVIEWS',
        title: t('colors.own_tasks'),
    },
    MY_UNANSWERED_REVIEWS: {
        filter: 'MY_UNANSWERED_REVIEWS',
        title: t('colors.own_tasks'),
    },
});

class TaskRatingAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        const query = props.location.query;
        const { t } = props;

        const currentFilterMapping =
            query && query.filter
                ? reviewAdministrationFilterMappings(t)[query.filter]
                : reviewAdministrationFilterMappings(t).MY_REVIEWS;

        this.tableHelper = new TableHelper();
        this.state = {
            tableData: [], // only containing props for table
            filter: currentFilterMapping.filter,
            tableTitle: currentFilterMapping.title,
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { selectedCompany } = this.props;

        this.props.fetchReviewsByFilter(selectedCompany, this.state.filter);
    }

    componentWillReceiveProps(nextProps) {
        const { reviews, selectedCompany } = nextProps;
        const query = nextProps.location.query;

        const { t } = this.props;

        const currentFilterMapping =
            query && query.filter
                ? reviewAdministrationFilterMappings(t)[query.filter]
                : reviewAdministrationFilterMappings(t).MY_REVIEWS;

        if (currentFilterMapping.filter !== this.state.filter) {
            this.setState({
                filter: currentFilterMapping.filter,
                tableTitle: currentFilterMapping.title,
            });
            this.props.fetchReviewsByFilter(selectedCompany, currentFilterMapping.filter);
        }

        if (reviews.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        if (
            !reviews.error &&
            reviews.action &&
            reviews.action.type !== 'REQUEST' &&
            !_.isEqual(this.props.reviews, reviews)
        ) {
            let tableData = this.state.tableData;

            switch (reviews.action.type) {
                case 'UPDATE': {
                    this.setState({ tableData: this.populateTableData(reviews) });
                    break;
                }
                case 'FETCH': {
                    this.setState({ tableData: this.populateTableData(reviews) });
                }
            }
        }

        this.tableHelper.processPagination(this.state.tableData.length);
    }

    populateTableData(reviews) {
        return reviews.items
            .filter(review => {
                switch (this.state.filter) {
                    case 'MY_UNANSWERED_REVIEWS':
                        return review.answerStatus !== 'SUBMITTED';
                    default:
                        return true;
                }
            })
            .map(review => {
                return {
                    id: review.id,
                    taskNumber: review.task.identifier,
                    comment: review.comment,
                    task: review.task,
                    customer: review.customer,
                    visible: review.visible,
                    customerMail: review.customer.email,
                    taskFromTime: review.task.taskFrom.time,
                    averageRating: this.getAverageRating(review.entries),
                    answerStatus: review.answerStatus === 'SUBMITTED' || review.answerStatus === 'REVIEWED',
                };
            });
    }

    render() {
        const { reviews, t } = this.props;
        const tableHeader =
            this.state.filter === 'MY_UNANSWERED_REVIEWS'
                ? t('administration.task_ratings.answer_rating')
                : t('administration.task_ratings.ratings');
        return (
            <div>
                <legend className="legend">{tableHeader}</legend>

                {(() => {
                    if (reviews.error) return <ErrorMessage object={reviews} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={this.state.tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    dataAlign="left"
                                    width="10"
                                    dataSort={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="taskNumber"
                                    dataAlign="left"
                                    width="20"
                                    dataSort={true}
                                    export={true}>
                                    {t('administration.task_ratings.task_number')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="customerMail"
                                    dataAlign="left"
                                    width="60"
                                    dataSort={true}
                                    export={true}>
                                    {t('administration.task_ratings.customer_email')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="taskFromTime"
                                    dataAlign="left"
                                    width="40"
                                    dataSort={true}
                                    export={true}>
                                    {t('administration.task_ratings.task_from_date')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="comment"
                                    dataAlign="left"
                                    width="100"
                                    dataSort={true}
                                    export={true}>
                                    {t('administration.task_ratings.comment')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="averageRating"
                                    dataAlign="right"
                                    width="20"
                                    dataSort={true}
                                    export={true}>
                                    {t('administration.task_ratings.avg_rating')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="visible"
                                    dataAlign="center"
                                    width="30"
                                    dataSort={true}
                                    dataFormat={this.getReviewVisibilityFormat}
                                    export={true}>
                                    {t('administration.task_ratings.visible')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="answerStatus"
                                    dataAlign="center"
                                    width="30"
                                    dataSort={true}
                                    dataFormat={this.getAnsweredVisibilityFormat}
                                    export={true}>
                                    {t('administration.task_ratings.answered')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="30"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    getAnsweredVisibilityFormat(cell) {
        const { t } = this.props;
        return cell === true ? (
            <span
                title={t('administration.task_ratings.answered')}
                className="glyphicon glyphicon-ok-circle text-success"
            />
        ) : (
            <span
                title={t('administration.task_ratings.unanswered')}
                className="fa fa-times-circle-o text-danger fa-lg"
            />
        );
    }

    getReviewVisibilityFormat(cell, row) {
        const { t } = this.props;
        return cell === true ? (
            <span
                title={t('administration.task_ratings.visible')}
                className="glyphicon glyphicon-ok-circle text-success"
            />
        ) : (
            <span
                title={t('administration.task_ratings.under_review')}
                className="fa fa-times-circle-o text-danger fa-lg"
            />
        );
    }

    getAverageRating(entries) {
        const rating = Object.values(entries).reduce((sum, val) => sum + val, 0) / Object.values(entries).length;
        return isNaN(rating) ? '-' : rating.toFixed(2);
    }

    openEditModal(review) {
        const { t } = this.props;
        this.props.openModal({
            component: RatingAnswerModal,
            componentProps: {
                id: review.id,
                review: review,
            },
            title: 'Review',
        });
    }

    tableActionsFormatter(cell, review) {
        const { t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.edit')}
                    onClick={() => {
                        this.openEditModal(review);
                    }}>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
            </div>
        );
    }
}

export default withTranslation()(composeModalActions(TaskRatingAdministration));
