import autoBind from 'react-autobind/src/autoBind';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Entities, fetchById, getUpdatedEntity, patch } from 'src/common';
import { setSliderIndex } from 'src/actions/form';
import ErrorMessage from 'src/components/misc/error-message';
import CircularProgress from 'material-ui/CircularProgress';
import { patchApproveOperatorAnswer, patchApproveOperatorReview, patchOperatorAnswer } from 'src/actions/ratings';
import AdminRatingReviewForm from 'src/components/admin/rating-reviews/components/admin-rating-review-form';

class AdminRatingReviewModal extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            startedFetching: false,
        };
    }

    componentWillMount() {
        const { id, selectedCompany } = this.props;
        this.props.fetchById(id, Entities.REVIEW, selectedCompany);
    }

    componentWillReceiveProps(nextProps) {
        const { review } = nextProps;
        if (review.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });
    }

    callSubmit() {
        return this.childForm.submit();
    }

    didFormChange() {
        return !this.childForm.pristine;
    }

    render() {
        const { review, t, reviewCategories } = this.props;

        const finishFetching = this.state.startedFetching && !review.isFetching;
        if (finishFetching) {
            if (review.error) return <ErrorMessage object={review} />;

            const initialValues = { ...review.content };

            return (
                <div>
                    <AdminRatingReviewForm
                        t={t}
                        review={review.content}
                        onSubmit={this.handleSubmit}
                        onSubmitCustomerComment={this.handleSubmit}
                        initialValues={initialValues}
                        selectedSliderIndex={this.props.selectedSliderIndex}
                        setSliderIndex={this.props.setSliderIndex}
                        ref={ref => (this.childForm = ref)}
                        filter={this.props.filter}
                        reviewCategories={reviewCategories}
                    />
                </div>
            );
        } else return <CircularProgress />;
    }

    handleSubmit(data) {
        const { handleClose, reviewCategories } = this.props;

        if (this.props.filter === 'REVIEW_NEEDS_APPROVAL') this.submitReviewApproval(data, reviewCategories);
        else this.props.patchApproveOperatorAnswer(data);
        handleClose();
    }

    submitReviewApproval(data, reviewCategories) {
        data.entries = Object.entries(data.entries).reduce((entry, [key, val]) => {
            const mappedKey = reviewCategories.items.find(reviewCategory => reviewCategory.category === key);
            entry[mappedKey.id] = val;
            return entry;
        }, {});
        this.props.patchApproveOperatorReview(data);
    }
}

const mapStateToProps = state => {
    return {
        review: getUpdatedEntity(state, Entities.REVIEW, state.selectedCompany),
        selectedCompany: state.selectedCompany,
        selectedSliderIndex: state.selectedSliderIndex,
    };
};

export default connect(
    mapStateToProps,
    {
        fetchById,
        patch,
        patchOperatorAnswer,
        setSliderIndex,
        patchApproveOperatorReview,
        patchApproveOperatorAnswer,
    },
    null,
    { withRef: true },
)(AdminRatingReviewModal);
