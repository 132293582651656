import autoBind from 'react-autobind';
import { Field, reduxForm } from 'redux-form';
import React, { Component } from 'react';
import moment from 'moment';
import { renderInput, renderInputNumber, renderRichTextEditor } from 'src/components/misc/redux-form-helpers';
import { connect } from 'react-redux';
import TripCategoryForm from 'src/components/trips/admin/category/TripCategoryForm';
import { Entities, getUpdatedEntity } from 'src/common';
import { entries } from 'lodash';

class AdminRatingReviewForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.state = {
            editCustomerCommentButton: this.props.review.visible,
            tableTitle: this.props.review.visible,
            startedFetching: false,
        };
    }

    render() {
        const { review, t, initialValues, handleSubmit, reviewCategories } = this.props;

        const ratingsEditable =
            Object.entries(review.entries).filter(([key, val]) => val === 0).length ===
            Object.keys(review.entries).length;

        const displayField = this.props.filter === 'REVIEW_NEEDS_APPROVAL' ? 'comment' : 'operatorAnswer';

        const fieldDisabled = displayField === 'comment' ? review.visible : false;

        return (
            <div>
                <form role="form" className="voffset" onSubmit={handleSubmit}>
                    <div>
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <label>{t('administration.task_ratings.customer_comment')}</label>
                                    <Field
                                        name={displayField}
                                        label={t('common_phrases.comment')}
                                        hintText={t('equipment.rating_hint')}
                                        component={renderInput}
                                        rows={3}
                                        maxRows={3}
                                        multiline
                                        disabled={fieldDisabled}
                                        fullWidth={true}
                                    />
                                </div>
                            </div>
                            <div className="row voffset">
                                <div className="col-md-6">{this.getReviewInfo(review.entries, ratingsEditable)}</div>
                                <div className="col-md-6">
                                    {this.getTaskDetails(review)}
                                    <p>{t('administration.task_ratings.operator_infos')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    getReviewInfo(entries, ratingsEditable) {
        const { t, reviewCategories } = this.props;

        let items = { ...entries };
        if (Object.keys(entries).length !== reviewCategories.items.length) {
            reviewCategories.items.forEach(item => {
                if (!items[item.category]) items[item.category] = 1.0;
            });
            ratingsEditable = true;
        }
        return (
            <>
                <label>{t('administration.task_ratings.category')}</label>
                <table className="table table-striped small" style={{ padding: 0 }}>
                    <thead className="text-left">
                        <td>{t('administration.task_ratings.category')}</td>
                        <td>{t('administration.task_ratings.evaluation')}</td>
                    </thead>
                    <tbody>
                        {Object.entries(items).map(([i, val]) => (
                            <tr key={i} style={{ padding: 0 }}>
                                <td style={{ padding: 0 }}>{i}</td>
                                <td className="td" style={{ padding: 0 }}>
                                    <Field
                                        style={{ fontSize: '9pt' }}
                                        name={`entries.${i}`}
                                        value={val}
                                        props={{
                                            value: 1.0,
                                        }}
                                        component={renderInputNumber}
                                        type="number"
                                        step={0.5}
                                        max={5}
                                        min={0.5}
                                        disabled={!ratingsEditable}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
        );
    }

    getTaskDetails(review) {
        const { task, customer } = review;
        const { t } = this.props;
        return (
            <div>
                <label>{t('administration.task_ratings.task_infos')}</label>
                <table className="table table-striped">
                    <tbody>
                        <tr>
                            <td>Datum:</td>
                            <td>
                                {moment(task.taskFrom.time).format()} - {task.bookingIdentifier}
                            </td>
                        </tr>
                        <tr>
                            <td>Kunde:</td>
                            <td>{customer.contactData.email}</td>
                        </tr>
                        <tr>
                            <td width="15%">Von:</td>
                            <td>{task.taskFrom.location.address}</td>
                        </tr>
                        <tr>
                            <td>Nach:</td>
                            <td>{task.taskTo.location.address}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

const validate = (values, props) => {
    const { t, reviewCategories } = props;
    const errors = {
        entries: {},
    };

    reviewCategories.items.forEach(item => {
        const category = Object.entries(values.entries).find(value => value[0] === item.category);
        console.log(category, 'category');
        if (category && category.value < 0.5)
            errors.entries[item.category] = t('administration.task_ratings.error_rating_review');
    });

    return errors;
};

export default reduxForm({
    form: 'AdminRatingReviewForm',
    validate,
})(AdminRatingReviewForm);
