import { connect } from 'react-redux';
import { Entities, fetchAll, getEntities, patch, Projection } from 'src/common/index';
import { fetchReviewsByFilterAdmin, patchApproveOperatorReview } from 'src/actions/ratings';
import RatingReviewAdministration from 'src/components/admin/rating-reviews/admin-rating-reviews-administration';

const mapStateToProps = state => {
    return {
        reviews: getEntities(state, Entities.REVIEW),
        reviewCategories: getEntities(state, Entities.REVIEW_CATEGORY),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchAll: entity => {
            dispatch(fetchAll(entity));
        },
        fetchReviewsByFilterAdmin: filter => {
            dispatch(fetchReviewsByFilterAdmin(filter, 'reviewAdmin'));
        },
        patchApproveOperatorReview: review => {
            dispatch(patchApproveOperatorReview(review));
        },
    };
};

const AdminRatingReviewAdministrationContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(RatingReviewAdministration);

export default AdminRatingReviewAdministrationContainer;
