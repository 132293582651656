import React, { Component } from 'react';
import autoBind from 'react-autobind/src/autoBind';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import {
    AssociationSearch,
    countryMenuItems,
    genderMenuItems,
    Location,
    renderInput,
    renderSelectField,
    SchoolSearch,
} from 'src/components/misc/redux-form-helpers';
import _ from 'lodash';
import { MenuItem } from 'material-ui';
import { isBlank, isEmailInvalid } from 'src/components/misc/validations';
import { connect } from 'react-redux';

class CustomerAdministrationNewCustomerForm extends Component {
    constructor(props) {
        super(props);
        autoBind(this);
        this.state = { customerGroupNeedAdditionalFields: false };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { customerGroupId, customerGroupNeedAdditionalFields, change } = this.props;
        if (customerGroupId !== prevProps.customerGroupId) {
            this.setState({ ...this.state, customerGroupNeedAdditionalFields: true });
        }
    }

    render() {
        const { handleSubmit, t } = this.props;

        return (
            <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="col-md-6">
                            <Field name="gender" label={t('contact_data.gender')} component={renderSelectField}>
                                {genderMenuItems(t)}
                            </Field>
                        </div>
                        <div className="col-md-6">
                            <Field
                                name="customerGroupId"
                                label={`${t('customer_group.group')} *`}
                                component={renderSelectField}>
                                {this.getCustomerGroupMenuItems()}
                            </Field>
                        </div>
                        {this.state.customerGroupNeedAdditionalFields && this.getCustomerGroupFormFields()}
                        <div className="col-md-12 voffset " style={{ marginBottom: 0 }}>
                            <p
                                style={{
                                    marginBottom: 0,
                                    borderBottom: '1px solid rgb(12, 102, 171)',
                                }}>
                                {' '}
                                {t('customer_details.contact_data')}{' '}
                            </p>
                        </div>
                        <div className="col-md-6">
                            <Field
                                name="firstName"
                                label={`${t('contact_data.first_name')} *`}
                                component={renderInput}
                            />
                        </div>
                        <div className="col-md-6">
                            <Field name="lastName" label={`${t('contact_data.last_name')} *`} component={renderInput} />
                        </div>
                        <div className="col-md-6">
                            <Field name="email" label={`${t('contact_data.email')} *`} component={renderInput}></Field>
                        </div>
                        <div className="col-md-6">
                            <Field name="phone" label={`${t('contact_data.tel_number')} *`} component={renderInput} />
                        </div>
                        <div className="col-md-6">
                            <Field
                                name="postCode"
                                label={`${t('contact_data.post_code')} *`}
                                hintText={t('contact_data.post_code_hint')}
                                component={renderInput}
                            />
                        </div>
                        <div className="col-md-6">
                            <Field name="city" label={`${t('contact_data.city')} *`} component={renderInput} />
                        </div>
                        <div className="col-md-6">
                            <Field name="street" label={`${t('contact_data.street')} *`} component={renderInput} />
                        </div>
                        <div className="col-md-6">
                            <Field
                                name="country"
                                label={`${t('contact_data.country')} *`}
                                component={renderSelectField}>
                                {countryMenuItems()}
                            </Field>
                        </div>
                    </div>
                </div>
            </form>
        );
    }

    getCustomerGroupMenuItems() {
        const { t, customerGroups } = this.props;

        return _.map(customerGroups.items, customerGroup => (
            <MenuItem
                key={customerGroup.id}
                value={customerGroup.id}
                primaryText={t(`customer_groups.id.${customerGroup.id}`)}
            />
        ));
    }

    getCustomerGroupFormFields() {
        const { customerGroupId, customerGroups, t } = this.props;

        if (customerGroupId === 3 || customerGroupId === 5 || customerGroupId === 2) {
            return (
                <>
                    <div className="col-md-12 voffset " style={{ marginBottom: 0 }}>
                        <p
                            style={{
                                marginBottom: 0,
                                borderBottom: '1px solid rgb(12, 102, 171)',
                            }}>
                            {' '}
                            {t('customer_details.customer_group')}{' '}
                        </p>
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="organizationName"
                            label={`${t('contact_data.business_name')} *`}
                            component={renderInput}
                        />
                    </div>
                    {this.getLocationField('contact_data.business_location')}
                    <div className="col-md-6">
                        <Field
                            name="organizationUID"
                            label={`${t('contact_data.organization_uid')}`}
                            component={renderInput}
                        />
                    </div>
                </>
            );
        } else if (customerGroupId === 1) {
            return (
                <>
                    <div className="col-md-12 voffset " style={{ marginBottom: 0 }}>
                        <p
                            style={{
                                marginBottom: 0,
                                borderBottom: '1px solid rgb(12, 102, 171)',
                            }}>
                            {' '}
                            {t('customer_details.customer_group')}{' '}
                        </p>
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="organizationName"
                            t={t}
                            label={`${t('contact_data.school_name')} *`}
                            component={SchoolSearch}
                            onChangeAddress={this.onChangeAddress}
                        />
                    </div>
                    {this.getLocationField('contact_data.school_location')}
                </>
            );
        } else if (customerGroupId === 7) {
            return (
                <>
                    <div className="col-md-12 voffset " style={{ marginBottom: 0 }}>
                        <p
                            style={{
                                marginBottom: 0,
                                borderBottom: '1px solid rgb(12, 102, 171)',
                            }}>
                            {' '}
                            {t('customer_details.customer_group')}{' '}
                        </p>
                    </div>
                    <div className="col-md-6">
                        <Field
                            name="organizationName"
                            t={t}
                            label={`${t('contact_data.association_name')} *`}
                            component={AssociationSearch}
                            onChangeAddress={this.onChangeAddress}
                        />
                    </div>
                    {this.getLocationField('contact_data.association_location')}
                </>
            );
        }
    }

    onChangeAddress(item) {
        item.address = item.fullAddress;
        this.props.change('CustomerAdministrationNewCustomerForm', 'organizationLocation', item);
    }

    getLocationField(label) {
        const { t } = this.props;

        return (
            <div className="col-md-6">
                <Field name="organizationLocation" label={`${t(label)} *`} component={Location} />
            </div>
        );
    }
}

const validate = (values, props) => {
    const { t, customerGroups } = props;
    const errors = {};

    if (values.gender == null) errors.gender = t('error_missing.fill_in_gender');
    if (values.customerGroupId == null) errors.customerGroup = t('error_missing.fill_in_customer_group');
    if (values.firstName == null) errors.firstName = t('error_missing.fill_in_first_name');
    if (values.lastName == null) errors.lastName = t('error_missing.fill_in_last_name');
    if (values.email == null) errors.email = t('error_missing.fill_in_email');
    if (values.email != null && isEmailInvalid(values.email)) errors.email = t('error_hint.error_invalid_email');
    if (values.postCode == null) errors.postCode = t('error_missing.fill_in_postal_code');
    if (values.country == null) errors.country = t('error_missing.fill_in_country');
    if (values.phone == null) errors.phone = t('error_missing.fill_in_phone');
    if (values.city == null) errors.city = t('error_missing.fill_in_city');
    if (values.street == null) errors.street = t('error_missing.fill_in_street');
    if (values.customerGroupId === 3 || values.customerGroupId === 7 || values.customerGroupId === 1) {
        if (isBlank(values.organizationName)) errors.organizationName = t('error_missing.fill_in_company_name');
        if (isBlank(values.organizationLocation) || typeof values.organizationLocation !== 'object')
            errors.organizationLocation = t('error_missing.fill_in_location');
    }
    if (values.customerGroupId === 7) {
        if (values.registerOrganization && isBlank(values.registrationNumber))
            errors.registrationNumber = t('associations.fill_in_registration_number');
    }

    return errors;
};

const selector = formValueSelector('CustomerAdministrationNewCustomerForm'); // select current form

const mapStateToProps = state => {
    const customerGroupId = selector(state, 'customerGroupId');
    return {
        customerGroupId,
    };
};
const mapDispatchToProps = {
    change,
};

export default reduxForm({
    form: 'CustomerAdministrationNewCustomerForm',
    validate,
})(connect(mapStateToProps, mapDispatchToProps)(CustomerAdministrationNewCustomerForm));
