import { connect } from 'react-redux';

import { deleteById, Entities, getEntities, patch, search, fetchAll } from 'src/common';
import { addNotification } from 'src/actions/notifications';
import CustomerAdministration from 'src/components/administration/customer/customer-administration';
import { getOwnCompany } from 'src/selectors/bus-companies';

const mapStateToProps = state => {
    return {
        busCompanies: getEntities(state, Entities.BUS_COMPANY),
        customers: getEntities(state, Entities.CUSTOMER, state.selectedCompany),
        customerGroups: getEntities(state, Entities.CUSTOMER_GROUP),
        selectedCompany: state.selectedCompany,
        busCompany: getOwnCompany(state),
    };
};

const CustomerAdministrationContainer = connect(mapStateToProps, {
    search,
    patch,
    deleteById,
    addNotification,
    fetchAll,
})(CustomerAdministration);

export default CustomerAdministrationContainer;
