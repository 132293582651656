import Cookies from 'js-cookie';
import CircularProgress from 'material-ui/CircularProgress';
import Toggle from 'material-ui/Toggle';
import moment from 'moment';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import _ from 'lodash';

import EventModal from 'src/components/events/event-modal';
import ConfirmDialog from 'src/components/misc/confirm-dialog';
import { ButtonColors, OfferStatus, TaskType } from 'src/utils/constants';
import { BUSFINDER_UI_URL, Entities } from 'src/common/index';
import TaskActionButtonHelper from 'src/utils/task-action-buttons';
import composeTaskActions from 'src/utils/task-action-wrapper';
import { priceFormatter } from 'src/utils/formatters';
import CustomTooltip from 'src/components/misc/CustomTooltip';

/**
 * Dynamic TaskList which displays all types of task
 * in the form of a table according to the selected filter
 */
class TaskList extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        const pageSize = Cookies.get('taskList-pageSize');
        this.state = {
            tableData: [], // only containing props for table
            taskEntities: [], // tasks with full props from selector
            startedFetching: false,
            filterChanged: false,
            selectedRows: [],
            pagination: {
                currentPage: 1,
                pageSize: pageSize ? parseInt(pageSize) : 10,
            },
        };
    }

    componentDidMount() {
        this.props.fetchOwnedBookingIntegration();
        this.taskActionButtonHelper = new TaskActionButtonHelper(this.props);
    }

    componentWillReceiveProps(nextProps) {
        const {
            tasks,
            receivedTask,
            drivers,
            buses,
            reviews,
            taskFilter,
            showExpiredTasks,
            showCancelledTasks,
            currentSelectedDriver,
        } = nextProps;

        // determine when fetching started
        if ((tasks.isFetching || (drivers && drivers.isFetching)) && !this.state.startedFetching)
            this.setState({ startedFetching: true });

        // if filter changed -> reset pagination
        if (
            taskFilter !== this.props.taskFilter ||
            showExpiredTasks !== this.props.showExpiredTasks ||
            showCancelledTasks !== this.props.showCancelledTasks
        ) {
            this.setState({
                filterChanged: true,
                pagination: {
                    pageSize: this.state.pagination.pageSize,
                    currentPage: 1,
                },
            });
        }

        // if tasks changed -> process them for table
        if (!tasks.error && tasks.action && tasks.action.type !== 'REQUEST' && !_.isEqual(this.props.tasks, tasks)) {
            let tableData = this.state.tableData;
            let taskEntities = this.state.taskEntities;
            let selectedRows = [],
                currentPage;

            // sort tasks by bookedAt or createdAt
            tasks.items.sort((a, b) => {
                const momentA = a.bookedAt ? moment(a.bookedAt) : moment(a.createdAt);
                const momentB = b.bookedAt ? moment(b.bookedAt) : moment(b.createdAt);

                return momentA.isBefore(momentB) ? 1 : -1;
            });

            switch (tasks.action.type) {
                case 'FETCH': {
                    taskEntities = tasks.items;
                    tableData = this.populateTableData(
                        tasks.items,
                        buses,
                        taskFilter,
                        reviews,
                        currentSelectedDriver,
                        showExpiredTasks,
                        showCancelledTasks,
                    );

                    // if receivedTask is set -> patch it as received and send customer email
                    if (receivedTask)
                        nextProps.onProceedReceivedTask({
                            id: receivedTask,
                            received: true,
                        });

                    break;
                }
                case 'UPDATE': {
                    const index = tableData.findIndex(task => task.id === tasks.items[0].id);
                    if (index !== -1) {
                        const indexOfTaskEntity = taskEntities.findIndex(task => task.id === tasks.items[0].id);
                        taskEntities[indexOfTaskEntity] = tasks.items[0];

                        const updatedTask = this.populateTableData(
                            tasks.items,
                            buses,
                            taskFilter,
                            reviews,
                            currentSelectedDriver,
                            showExpiredTasks,
                            showCancelledTasks,
                        )[0];

                        // if task is still available because of filter -> update it
                        if (updatedTask) {
                            tableData[index] = updatedTask;
                            selectedRows[0] = updatedTask.id;
                            currentPage = Math.ceil((index + 1) / this.state.pagination.pageSize);
                            nextProps.selectEvent(taskEntities[index]);
                        }

                        // else remove the task from the list (because task is not appropriate for selected filter)
                        else {
                            tableData.splice(index, 1);
                            nextProps.selectEvent('');
                        }
                    }
                    break;
                }
                case 'SAVE': {
                    const newTask = this.populateTableData(
                        tasks.items,
                        buses,
                        taskFilter,
                        reviews,
                        currentSelectedDriver,
                        showExpiredTasks,
                        showCancelledTasks,
                    )[0];

                    // if task is still available because of filter -> save it
                    if (newTask) {
                        taskEntities.push(tasks.items[0]);
                        tableData.push(newTask);
                        selectedRows[0] = newTask.id;
                        currentPage = Math.ceil(tableData.length / this.state.pagination.pageSize);
                        nextProps.selectEvent(tasks.items[0]);
                    }
                    break;
                }
                case 'DELETE': {
                    _.remove(taskEntities, task => task.id === tasks.items[0]);
                    _.remove(tableData, task => task.id === tasks.items[0]);
                    break;
                }
                default:
                    console.warn('Unexpected tasks.action.type: ', tasks.action.type);
                    break;
            }

            // update tasks and auto adjust pagination if necessary
            if (tableData.length / this.state.pagination.pageSize <= this.state.pagination.currentPage - 1) {
                this.setState({
                    tableData: tableData,
                    taskEntities: taskEntities,
                    selectedRows: selectedRows,
                    filterChanged: false,
                    pagination: {
                        currentPage: this.state.pagination.currentPage > 1 ? this.state.pagination.currentPage - 1 : 1,
                        pageSize: this.state.pagination.pageSize,
                    },
                });
            }

            // otherwise only update tasks
            else {
                this.setState({
                    tableData: tableData,
                    taskEntities: taskEntities,
                    selectedRows: selectedRows,
                    filterChanged: false,
                    pagination: {
                        currentPage: currentPage ? currentPage : this.state.pagination.currentPage,
                        pageSize: this.state.pagination.pageSize,
                    },
                });
            }
        }
    }

    render() {
        const { isTaskExchangeView, taskFilter, tasks, busCompany, currentSelectedDriver, t } = this.props;
        const finishFetching =
            this.state.startedFetching && tasks && !tasks.isFetching && busCompany && !busCompany.isFetching;

        // options for react bootstrap table
        const options = {
            sizePerPageList: [10, 20, 50, 100],
            sizePerPage: this.state.pagination.pageSize,
            exportCSVText: t('common_phrases.export_list'),
            noDataText:
                !finishFetching || (this.state.filterChanged && taskFilter !== 'TASKS_BY_DRIVERS') ? (
                    <CircularProgress />
                ) : (
                    t('tasks.nothing_found')
                ),
            clearSearch: true,
            page: this.state.pagination.currentPage,
            onPageChange: (page, size) => {
                this.setState({
                    pagination: {
                        pageSize: size,
                        currentPage: page,
                    },
                });

                Cookies.set('taskList-pageSize', size, { expires: 10 * 365, secure: true, sameSite: 'none' });
            },
        };

        // options for select row on react bootstrap table
        const selectRowProp = {
            mode: 'radio',
            hideSelectColumn: true,
            clickToSelect: true,
            bgColor: 'rgb(173, 216, 230)',
            onSelect: this.onRowSelect,
            selected: this.state.selectedRows,
        };
        const isRatioClient = busCompany.entity.ratioApiKey && busCompany.entity.ratioClient;

        return (
            <div>
                {(() => {
                    if (taskFilter === 'TASKS_BY_DRIVERS' && !currentSelectedDriver) return <div />;
                    else if (!tasks.error) {
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={
                                    this.state.filterChanged && taskFilter !== 'TASKS_BY_DRIVERS'
                                        ? []
                                        : this.state.tableData
                                }
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={options}
                                selectRow={selectRowProp}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}>
                                    {t('common_phrases.id')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="foreignCompany"
                                    width="200"
                                    dataSort={true}
                                    hidden={!isTaskExchangeView}>
                                    {t('events.released_from')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="created"
                                    dataSort={true}
                                    hidden={!isTaskExchangeView}>
                                    {t('events.released_at')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="bookingIdentifier"
                                    width="100"
                                    export={true}
                                    dataSort={true}
                                    hidden={!this.isBookedTaskView(taskFilter)}>
                                    {t('booked_tasks.booking_number')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="identifier" width="90" dataSort={true}>
                                    {t('booked_tasks.task_number')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="bookedAt"
                                    width="120"
                                    dataFormat={this.dateFormatter}
                                    csvFormat={this.dateFormatter}
                                    dataSort={true}
                                    hidden={!this.isBookedTaskView(taskFilter)}>
                                    {t('booked_tasks.booked_at')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="name"
                                    width="180"
                                    dataSort={true}
                                    hidden={this.isBookedTaskView(taskFilter)}>
                                    {t('common_phrases.name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="bookingIntegrationUrl"
                                    width="100"
                                    dataSort={true}
                                    hidden={!this.isBookedTaskView(taskFilter)}>
                                    {t('booked_tasks.booking_integration')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="customerEmail"
                                    width="130"
                                    dataSort={true}
                                    hidden={!this.isBookedTaskView(taskFilter)}>
                                    {t('tasks.customer_email')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="customerFullName" hidden={true}>
                                    {t('tasks.customer_full_name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="state"
                                    width="50"
                                    dataAlign="center"
                                    dataFormat={this.statusFormatter}
                                    export={false}
                                    hidden={isTaskExchangeView}>
                                    {t('ticket.status')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    width="120"
                                    dataField="bus"
                                    dataSort={true}
                                    dataFormat={this.busFormatter}
                                    hidden={isTaskExchangeView}>
                                    {t('search.bus')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="passengers"
                                    dataAlign="center"
                                    width="45"
                                    dataSort={true}>
                                    {t('task_details.pax')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="fromCity"
                                    width="110"
                                    dataSort={true}
                                    dataFormat={this.fromCityFormatter}
                                    export={false}>
                                    {t('booked_tasks.from')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="fromAddress"
                                    dataSort={true}
                                    hidden={true}>
                                    {t('booked_tasks.from')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="fromTime"
                                    width="120"
                                    dataFormat={this.dateFormatter}
                                    csvFormat={this.dateFormatter}
                                    dataSort={true}>
                                    {t('tasks.departure_time')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="toCity"
                                    width="110"
                                    dataSort={true}
                                    dataFormat={this.toCityFormatter}
                                    export={false}>
                                    {t('booked_tasks.to')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="toAddress"
                                    dataSort={true}
                                    hidden={true}>
                                    {t('booked_tasks.to')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="toTime"
                                    width="120"
                                    dataFormat={this.dateFormatter}
                                    csvFormat={this.dateFormatter}
                                    dataSort={true}>
                                    {t('tasks.arrival_time')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="cleared"
                                    width="70"
                                    dataFormat={this.clearedFormatter}
                                    dataSort={true}
                                    hidden={isTaskExchangeView}>
                                    {t('tasks.calculated')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="cancelled"
                                    width="70"
                                    dataFormat={this.cancelledFormatter}
                                    dataSort={true}
                                    hidden={isTaskExchangeView}>
                                    {t('booked_tasks.cancelled')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    width="80"
                                    dataField="price"
                                    dataFormat={priceFormatter}
                                    csvFormat={priceFormatter}
                                    dataSort={true}>
                                    {t('booked_tasks.price')}
                                </TableHeaderColumn>
                                {isRatioClient && (
                                    <TableHeaderColumn columnTitle={true} width="100" dataField="ratioTransferNumber">
                                        {t('tasks.ratio_transfer_number')}
                                    </TableHeaderColumn>
                                )}
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="230"
                                    dataFormat={this.actionFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                    } else return <div />;
                })()}
            </div>
        );
    }

    populateTableData(
        taskEntities,
        buses,
        taskFilter,
        reviews,
        currentSelectedDriver,
        showExpiredTasks,
        showCancelledTasks,
    ) {
        return taskEntities
            .filter(task => {
                if (!showExpiredTasks && moment().isAfter(moment(task.to.time))) return false;
                if (!showCancelledTasks && task.cancelled) return false;
                else {
                    switch (taskFilter) {
                        case 'MY_TASKS':
                            return [
                                TaskType.MY_OPEN_TASK,
                                TaskType.MY_ASSIGNED_TASK,
                                TaskType.BOOKED_BY_CUSTOMER_ASSIGNED,
                                TaskType.BOOKED_BY_CUSTOMER_OPEN,
                                TaskType.MY_OPEN_EXCHANGED_TASK_PENDING,
                                TaskType.FOREIGN_OPEN_EXCHANGED_TASK_ACCEPTED,
                                TaskType.FOREIGN_ASSIGNED_EXCHANGED_TASK,
                            ].includes(task.type);
                        case 'MY_OPEN_TASKS':
                            return [
                                TaskType.MY_OPEN_TASK,
                                TaskType.BOOKED_BY_CUSTOMER_OPEN,
                                TaskType.MY_OPEN_EXCHANGED_TASK_PENDING,
                                TaskType.FOREIGN_OPEN_EXCHANGED_TASK_ACCEPTED,
                            ].includes(task.type);
                        case 'MY_ASSIGNED_TASKS':
                            return [
                                TaskType.MY_ASSIGNED_TASK,
                                TaskType.BOOKED_BY_CUSTOMER_ASSIGNED,
                                TaskType.FOREIGN_ASSIGNED_EXCHANGED_TASK,
                            ].includes(task.type);
                        case 'MY_RELEASED_TASKS':
                            return (
                                task.type === TaskType.MY_OPEN_EXCHANGED_TASK_PENDING ||
                                task.type === TaskType.MY_OPEN_EXCHANGED_TASK_ACCEPTED ||
                                task.type === TaskType.MY_ASSIGNED_EXCHANGED_TASK
                            );
                        case 'MY_RELEASED_TASKS_PENDING':
                            return task.type === TaskType.MY_OPEN_EXCHANGED_TASK_PENDING;
                        case 'MY_RELEASED_TASKS_ACCEPTED':
                            return (
                                task.type === TaskType.MY_OPEN_EXCHANGED_TASK_ACCEPTED ||
                                task.type === TaskType.MY_ASSIGNED_EXCHANGED_TASK
                            );
                        case 'FOREIGN_RELEASED_TASKS':
                            return (
                                task.type === TaskType.FOREIGN_OPEN_EXCHANGED_TASK_PENDING ||
                                task.type === TaskType.FOREIGN_OPEN_EXCHANGED_TASK_ACCEPTED ||
                                task.type === TaskType.FOREIGN_ASSIGNED_EXCHANGED_TASK
                            );
                        case 'FOREIGN_RELEASED_TASKS_PENDING':
                            return task.type === TaskType.FOREIGN_OPEN_EXCHANGED_TASK_PENDING;
                        case 'FOREIGN_RELEASED_TASKS_ACCEPTED':
                            return (
                                task.type === TaskType.FOREIGN_OPEN_EXCHANGED_TASK_ACCEPTED ||
                                task.type === TaskType.FOREIGN_ASSIGNED_EXCHANGED_TASK
                            );
                        case 'BOOKED_BY_CUSTOMER':
                            return task.customer;
                        case 'BOOKED_BY_CUSTOMER_WITH_REVIEW':
                            return task.customer && task.review && reviews[task.review].visible;
                        case 'BOOKED_BY_CUSTOMER_WITHOUT_REVIEW':
                            return task.customer && (!task.review || (task.review && !reviews[task.review].visible));
                        case 'BOOKED_BY_CUSTOMER_REMINDER_DRIVER_DETAILS':
                            return task.customer && task.remindToSendDriverDetailsToCustomer;
                        case 'BOOKED_BY_CUSTOMER_NOT_RECEIVED':
                            return task.customer && !task.received;
                        case 'BOOKED_BY_CUSTOMER_ON_INTEGRATION':
                            return task.customer && task.bookingIntegration;
                        case 'TASKS_BY_DRIVERS':
                            return (
                                currentSelectedDriver &&
                                (task.firstDriver === currentSelectedDriver ||
                                    task.secondDriver === currentSelectedDriver)
                            );
                        case 'NOT_CLEARED':
                            return !task.cleared;
                        case 'CLEARED':
                            return task.cleared;
                        case 'OPEN_OFFER':
                            return task.offerStatus === OfferStatus.OPEN;
                        case 'ACCEPTED_OFFER':
                            return task.offerStatus === OfferStatus.ACCEPTED;
                        case 'DECLINED_OFFER':
                            return task.offerStatus === OfferStatus.DECLINED;
                        case 'DELETED_OFFER':
                            return task.offerStatus === OfferStatus.DELETED;
                        case 'ALL_OFFERS':
                            return task.offerStatus != null;
                        default:
                            return true;
                    }
                }
            })
            .map(task => {
                let foreignCompany, created;

                if (task.exchangedTasks && task.exchangedTasks.length > 0) {
                    foreignCompany = task.exchangedTasks[0].ownCompanyDetails.companyName;
                    created = task.exchangedTasks[0].createdAt;
                }
                return {
                    id: task.id,
                    name: task.name,
                    bookedAt: task.bookedAt,
                    identifier: task.identifier,
                    bookingIdentifier: task.bookingIdentifier,
                    cleared: task.cleared,
                    cancelled: task.cancelled,
                    cancellationAllowedByOperators: task.cancellationAllowedByOperators,
                    state: task.state,
                    offerStatus: task.offerStatus,
                    bus: this.populateBuses(task, buses),
                    passengers: task.passengers,
                    fromAddress: task.from.location.address,
                    fromCity: task.from.location.city,
                    fromTime: task.from.time,
                    toAddress: task.to.location.address,
                    toCity: task.to.location.city,
                    toTime: task.to.time,
                    type: task.type,
                    price:
                        task.type === TaskType.FOREIGN_OPEN_EXCHANGED_TASK_PENDING
                            ? task.exchangedTasks[0].price
                            : task.reducedPrice,
                    foreignCompany: foreignCompany,
                    created: created,
                    reservation: task.reservation,
                    customerEmail: task.customerContactData && task.customerContactData.email,
                    customerFullName: task.customerContactData && task.customerContactData.fullName,
                    ratioTransferNumber: task.ratioTransferNumber,
                    bookingIntegrationUrl: task.bookingIntegration && this.urlHost(task.bookingIntegration.url),
                    ratioBasisVorgangsNummer: task.ratioBasisVorgangsNummer,
                };
            });
    }

    handleClose() {
        this.setState({
            modal: {
                component: undefined,
                open: false,
            },
        });
    }

    openEventModal(props) {
        this.props.openModal({
            component: EventModal,
            componentProps: {
                id: props.id,
                eventType: props.eventType,
                isCreate: false,
                busAvailableSeats: props.busAvailableSeats,
            },
            title: props.title,
            mandatoryFields: true,
        });
    }

    openConfirmDeleteDialog(event) {
        const { t } = this.props;
        //TODO: before delete, we should reset the table filter this.table.reset() (but there is no ref here)
        this.props.openModal({
            component: ConfirmDialog,
            componentProps: {
                event: event,
                onProceedDialog: this.onProceedDeleteTask,
                bodyText: t('tasks.confirm_delete'),
                proceedText: t('user_functionality.delete'),
                cancelText: t('common_phrases.no'),
                cancelButtonColor: ButtonColors.NOTIFY,
                proceedButtonColor: ButtonColors.DANGER,
            },
            title: event.name,
            noButtons: true,
        });
    }

    onProceedDeleteTask(task) {
        this.props.deleteById(task.id, Entities.TASK, this.props.selectedCompany);
    }

    populateBuses(task, buses) {
        if (
            task.bus === null ||
            !task.bus ||
            [
                TaskType.MY_OPEN_EXCHANGED_TASK_ACCEPTED,
                TaskType.MY_ASSIGNED_EXCHANGED_TASK,
                TaskType.FOREIGN_OPEN_EXCHANGED_TASK_PENDING,
            ].includes(task.type)
        )
            return null;
        else return buses[task.bus].name;
    }

    busFormatter(bus, row) {
        return bus ? bus : '-';
    }

    urlHost(url) {
        const host = url == null || url.length === 0 ? undefined : new URL(url).host;
        if (host) return host;
        else return new URL(BUSFINDER_UI_URL).host;
    }

    clearedFormatter(cell, task) {
        return (
            <Toggle
                toggled={task.cleared}
                onToggle={() =>
                    this.props.patch(
                        {
                            id: task.id,
                            cleared: !task.cleared,
                        },
                        Entities.TASK,
                        this.props.selectedCompany,
                    )
                }
            />
        );
    }

    cancelledFormatter(cell, task) {
        const { t } = this.props;

        const cancellationAllowed = task.cancellationAllowedByOperators;

        const title = task.cancelled ? t('booked_tasks.cancelled') : t('booked_tasks.not_cancelled');

        return (
            <Toggle
                disabled={!cancellationAllowed}
                toggled={task.cancelled}
                title={cancellationAllowed ? title : t('tasks.not_cancellable', { title: title })}
                onToggle={() => {
                    this.props.openModal({
                        component: ConfirmDialog,
                        componentProps: {
                            event: task,
                            onProceedDialog: () => {
                                this.props.patch(
                                    {
                                        id: task.id,
                                        cancelled: true,
                                    },
                                    Entities.TASK,
                                    this.props.selectedCompany,
                                );
                            },
                            bodyText: t('tasks.confirm_cancellation'),
                            proceedText: t('common_phrases.yes'),
                            cancelText: t('common_phrases.no'),
                            cancelButtonColor: ButtonColors.NOTIFY,
                            proceedButtonColor: ButtonColors.DANGER,
                        },
                        title: task.name + ' ' + t('user_functionality.cancel'),
                        noButtons: true,
                    });
                }}
            />
        );
    }

    /**
     * a status formatter for the React bootstrap table. replaces the state text with appropriate icons
     */
    statusFormatter(cell, task) {
        const { t } = this.props;
        const taskTypeColors = this.props.busCompany.entity.taskTypeColors;

        if (this.props.isOfferView && task.offerStatus === OfferStatus.ACCEPTED)
            return <span title={t('tasks.accepted_offer')} style={{ color: 'green' }} className="fa fa-circle" />;

        switch (task.type) {
            case TaskType.MY_OPEN_TASK:
                return (
                    <span
                        title={t('tasks.own_open')}
                        style={{ color: taskTypeColors[task.type] }}
                        className="fa fa-circle"
                    />
                );
            case TaskType.MY_ASSIGNED_TASK:
                return (
                    <span
                        title={t('tasks.own_assigned')}
                        style={{ color: taskTypeColors[task.type] }}
                        className="fa fa-circle"
                    />
                );
            case TaskType.BOOKED_BY_CUSTOMER_OPEN:
                return (
                    <span
                        title={t('tasks.booked_client_no_bus')}
                        style={{ color: taskTypeColors[task.type] }}
                        className="fa fa-circle"
                    />
                );
            case TaskType.BOOKED_BY_CUSTOMER_ASSIGNED:
                return (
                    <span
                        title={t('tasks.booked_client_bus')}
                        style={{ color: taskTypeColors[task.type] }}
                        className="fa fa-circle"
                    />
                );
            case TaskType.OPEN_OFFER:
                return (
                    <span
                        title={t('task_details.open_offer')}
                        style={{ color: taskTypeColors[task.type] }}
                        className="fa fa-circle"
                    />
                );
            case TaskType.DECLINED_OFFER:
                return (
                    <span
                        title={t('task_details.declined_offer')}
                        style={{ color: 'orange' }}
                        className="fa fa-circle"
                    />
                );
            case TaskType.DELETED_OFFER:
                return (
                    <span title={t('task_details.deleted_offer')} style={{ color: 'red' }} className="fa fa-circle" />
                );
            case TaskType.MY_OPEN_EXCHANGED_TASK_PENDING:
                return (
                    <span
                        title={t('tasks.released_pending')}
                        style={{ color: taskTypeColors[task.type] }}
                        className="fa fa-circle"
                    />
                );
            case TaskType.MY_OPEN_EXCHANGED_TASK_ACCEPTED:
                return (
                    <span
                        title={t('tasks.released_accepted')}
                        style={{ color: taskTypeColors[task.type] }}
                        className="fa fa-circle"
                    />
                );
            case TaskType.MY_ASSIGNED_EXCHANGED_TASK:
                return (
                    <span
                        title={t('tasks.released_accepted_bus')}
                        style={{ color: taskTypeColors[task.type] }}
                        className="fa fa-circle"
                    />
                );
            case TaskType.FOREIGN_OPEN_EXCHANGED_TASK_PENDING:
                return (
                    <span
                        title={t('tasks.released_extern_pending')}
                        style={{ color: taskTypeColors[task.type] }}
                        className="fa fa-circle"
                    />
                );
            case TaskType.FOREIGN_OPEN_EXCHANGED_TASK_ACCEPTED:
                return (
                    <span
                        title={t('tasks.released_extern_accepted')}
                        style={{ color: taskTypeColors[task.type] }}
                        className="fa fa-circle"
                    />
                );
            case TaskType.FOREIGN_ASSIGNED_EXCHANGED_TASK:
                return (
                    <span
                        title={t('tasks.released_extern_accepted_bus')}
                        style={{ color: taskTypeColors[task.type] }}
                        className="fa fa-circle"
                    />
                );

            default:
                return task.type;
        }
    }

    /**
     * an action formatter to display the appropriate actions for each task
     */
    actionFormatter(cell, row) {
        // find according task
        const task = this.state.taskEntities[this.state.taskEntities.findIndex(task => task.id === row.id)];

        if (task) {
            return (
                <span>
                    {this.taskActionButtonHelper
                        .getTaskActionButtonsByTask({
                            t: this.props.t,
                            task: task,
                            reviews: this.props.reviews,
                            externTasks: this.props.busCompany.entity.externTasks,
                            csBusCompany:
                                this.props.busCompany.entity.ftpCompanyId != null &&
                                this.props.busCompany.entity.ftpCompanyId.indexOf('CSBUS') === 0,
                            integration: this.props.integration.content,
                        })
                        .map((actionButton, index) => {
                            return <span key={index}>{actionButton}</span>;
                        })}
                </span>
            );
        }

        return null;
    }

    /**
     * a date formatter to display dates in an appropriate format
     */
    dateFormatter(cell, row) {
        return cell ? `${moment(cell).format()}` : '-';
    }

    fromCityFormatter(cell, row) {
        return (
            <CustomTooltip id={`toCity-${row.id}`} text={row.fromAddress} place="bottom">
                {cell}
            </CustomTooltip>
        );
    }

    toCityFormatter(cell, row) {
        return (
            <CustomTooltip id={`fromCity-${row.id}`} text={row.toAddress} place="bottom">
                {cell}
            </CustomTooltip>
        );
    }

    /**
     * callback which is triggered when a row is selected
     * sets the selected event in the state to display them in the details view
     */
    onRowSelect(row, isSelected) {
        const index = this.state.taskEntities.findIndex(task => task.id === row.id);

        if (index !== -1) {
            this.props.selectEvent(this.state.taskEntities[index]);
            this.setState({ selectedRows: [row.id] });
        } else {
            this.props.selectEvent('');
            this.setState({ selectedRows: [] });
        }
    }

    isBookedTaskView(taskFilter) {
        return [
            'BOOKED_BY_CUSTOMER',
            'BOOKED_BY_CUSTOMER_WITH_REVIEW',
            'BOOKED_BY_CUSTOMER_WITHOUT_REVIEW',
            'BOOKED_BY_CUSTOMER_REMINDER_DRIVER_DETAILS',
            'BOOKED_BY_CUSTOMER_NOT_RECEIVED',
            'BOOKED_BY_CUSTOMER_ON_INTEGRATION',
        ].includes(taskFilter);
    }
}

export default composeTaskActions(TaskList);
