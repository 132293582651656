import { asyncRequest, Entities, getFileNameFromResponse, Projection, TOMCAT_URL } from 'src/common';
import FileSaver from 'file-saver';
import CircularProgress from 'material-ui/CircularProgress';
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { withTranslation } from 'react-i18next';
import CustomerAdministrationImportModal from 'src/components/administration/customer-administration-import-modal';
import CustomerDiscountGroupEditModal from 'src/components/administration/customer-discount-group-edit-modal';
import ErrorMessage from 'src/components/misc/error-message';
import { ButtonColors, genderName } from 'src/utils/constants';
import composeModalActions from 'src/utils/modal-action-wrapper';
import TableHelper from 'src/utils/table-helper';
import ConfirmDialog from '../../misc/confirm-dialog';
import SeasonCalculationEditModal from 'src/components/administration/season-calculation-edit-modal';
import CustomerAdministrationNewCustomerModal from 'src/components/administration/customer/components/customer-administration-new-customer-modal';

class CustomerAdministration extends Component {
    constructor(props) {
        super(props);
        autoBind(this);

        this.tableHelper = new TableHelper();
        this.state = {
            startedFetching: false,
        };
    }

    componentDidMount() {
        this.fetchCustomers();
        this.props.fetchAll(Entities.CUSTOMER_GROUP);
    }

    shouldComponentUpdate(nextProps) {
        return !nextProps.modal.open;
    }

    componentWillReceiveProps(nextProps) {
        const { customers } = nextProps;

        if (customers.isFetching && !this.state.startedFetching) this.setState({ startedFetching: true });

        this.tableHelper.processPagination(customers.items.length);
    }

    componentDidUpdate(prevProps) {
        if (this.tableHelper.forcePaginationAfterUpdate(prevProps.customers, this.props.customers, this.table)) {
            this.setState({ renderSelection: !this.state.renderSelection });
        }
    }

    fetchCustomers() {
        this.props.search(Entities.CUSTOMER, 'byCompany', this.props.selectedCompany, Projection.FULL, true);
    }

    render() {
        const { t, customers } = this.props;
        const finishFetching = this.state.startedFetching && !customers.isFetching;

        const isImportRatioCustomerModuleEnabled =
            this.props.busCompany.entity && this.props.busCompany.entity.importRatioCustomerModuleEnabled;

        const tableData = [];
        if (finishFetching && customers.items.length > 0) {
            customers.items.map(customer => {
                tableData.push({
                    id: customer.id,
                    email: customer.email,
                    firstName: customer.contactData.firstName,
                    lastName: customer.contactData.lastName,
                    gender: customer.contactData.gender,
                    address: customer.contactData.address,
                    customerGroup: customer.customerGroup,
                    customerGroupName: customer.customerGroup
                        ? t(`customer_groups.id.${customer.customerGroup.id}`)
                        : '-',
                    discountGroup: customer.discountGroup,
                    discountGroupName: customer.discountGroup ? customer.discountGroup.name : '-',
                    contactData: customer.contactData,
                    registrationConfirmed: customer.registrationConfirmed,
                    importedByCompanyId: customer.importedByCompanyId,
                    ratioCustomerId: customer.ratioCustomerId,
                });
            });
        }

        return (
            <div>
                <legend className="legend">
                    {t('customer_details.customer')}
                    {isImportRatioCustomerModuleEnabled && this.renderImportRatioCustomerButton()}
                    <div className="pull-right">
                        <button
                            className="btn btn-xs btn-link nav-link pull-right"
                            onClick={() => {
                                this.props.openModal({
                                    component: CustomerAdministrationNewCustomerModal,
                                    componentProps: {
                                        fetchCustomers: this.fetchCustomers,
                                        customerGroups: this.props.customerGroups,
                                    },
                                    title: t('customer_details.customer'),
                                    mandatory: true,
                                });
                            }}>
                            <span className="glyphicon glyphicon-plus" />
                            &nbsp;{t('customer_administration.new_customer')}
                        </button>
                    </div>
                </legend>

                {(() => {
                    if (!finishFetching) return <CircularProgress />;
                    else if (customers.error) return <ErrorMessage object={customers} />;
                    else
                        return (
                            <BootstrapTable
                                ref={ref => (this.table = ref)}
                                data={tableData}
                                exportCSV={true}
                                striped={true}
                                hover={true}
                                condensed={true}
                                pagination={true}
                                options={Object.assign({}, this.tableHelper.getOptions(finishFetching))}
                                selectRow={Object.assign({}, this.tableHelper.getRowProps())}
                                searchPlaceholder={t('common_phrases.search')}
                                search={true}>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="id"
                                    hidden={true}
                                    isKey={true}
                                    export={true}
                                />
                                <TableHeaderColumn columnTitle={true} dataField="email" dataSort={true}>
                                    {t('contact_data.username')}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    columnTitle={true}
                                    dataField="gender"
                                    dataSort={true}
                                    dataFormat={genderName}
                                    csvFormat={genderName}>
                                    {t('contact_data.gender')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="firstName" dataSort={true}>
                                    {t('contact_data.first_name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="lastName" dataSort={true}>
                                    {t('contact_data.last_name')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="address" dataSort={true}>
                                    {t('contact_data.address')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="customerGroupName" dataSort={true}>
                                    {t('customer_group.group')}
                                </TableHeaderColumn>
                                <TableHeaderColumn columnTitle={true} dataField="discountGroupName" dataSort={true}>
                                    {t('administration.discount_group')}
                                </TableHeaderColumn>
                                {isImportRatioCustomerModuleEnabled && (
                                    <TableHeaderColumn columnTitle={true} dataSort={true} dataField="ratioCustomerId">
                                        {t('contact_data.ratio_customer_id')}
                                    </TableHeaderColumn>
                                )}
                                <TableHeaderColumn
                                    dataField="actions"
                                    width="90"
                                    dataFormat={this.tableActionsFormatter}
                                    export={false}>
                                    {t('common_phrases.actions')}
                                </TableHeaderColumn>
                            </BootstrapTable>
                        );
                })()}
            </div>
        );
    }

    sendRegistrationConfirmationEmail(customer) {
        const { t, addNotification } = this.props;
        asyncRequest({
            method: 'POST',
            endpoint: `${TOMCAT_URL}public/send-password-reset-link`,
            body: {
                email: customer.email,
            },
        })
            .then(response => {
                addNotification(
                    t('customer_details.sent'),
                    t('customer_details.sent_confirmation_email'),
                    'success',
                    'tr',
                );
            })
            .catch(error => {
                console.error('Error sending confirmation email', error);
            });
    }

    printRegistrationConfirmationLinkAsQRCode(customer) {
        asyncRequest({
            method: 'POST',
            endpoint: `${TOMCAT_URL}api/${Entities.RATIO_CUSTOMER.repository}/print-qr-code?customerId=${customer.id}`,
        })
            .then(response => {
                response.blob().then(blob => {
                    FileSaver.saveAs(blob, getFileNameFromResponse(response));
                });
            })
            .catch(error => {
                console.error('Error downloading qr code', error);
            });
    }

    tableActionsFormatter(cell, customer) {
        const { t } = this.props;
        return (
            <div>
                <button
                    type="button"
                    className="btn btn-xs transparent"
                    title={t('user_functionality.edit')}
                    onClick={() =>
                        this.props.openModal({
                            component: CustomerDiscountGroupEditModal,
                            componentProps: {
                                id: customer.id,
                                ratioCustomerId: customer.ratioCustomerId,
                                isImportRatioCustomerModuleEnabled:
                                    this.props.busCompany.entity &&
                                    this.props.busCompany.entity.importRatioCustomerModuleEnabled,
                            },
                            title: customer.email,
                            mandatoryFields: true,
                        })
                    }>
                    <span className="glyphicon glyphicon-pencil text-info" />
                </button>
                {/*TODO: BUF-1762 registration workflow for imported customers - buttons hidden for now*/}
                {false && !customer.registrationConfirmed && customer.importedByCompanyId && (
                    <>
                        <button
                            type="button"
                            className="btn btn-xs transparent"
                            title={t('customer_details.send_confirmation_email_title')}
                            onClick={() =>
                                this.props.openModal({
                                    component: ConfirmDialog,
                                    componentProps: {
                                        bodyText: t('customer_details.send_confirmation_email_body'),
                                        proceedText: t('user_functionality.send'),
                                        proceedButtonColor: ButtonColors.NOTIFY,
                                        cancelText: t('common_phrases.abort'),
                                        cancelButtonColor: ButtonColors.DANGER,
                                        onProceedDialog: () => this.sendRegistrationConfirmationEmail(customer),
                                    },
                                    title: t('customer_details.send_confirmation_email_title'),
                                    noButtons: true,
                                })
                            }>
                            <span className="glyphicon glyphicon-envelope text-info" />
                        </button>
                        <button
                            type="button"
                            className="btn btn-xs transparent"
                            title={t('customer_details.print_qr_code_title')}
                            onClick={() =>
                                this.props.openModal({
                                    component: ConfirmDialog,
                                    componentProps: {
                                        bodyText: t('customer_details.print_qr_code_body'),
                                        proceedText: t('common_phrases.download'),
                                        proceedButtonColor: ButtonColors.NOTIFY,
                                        cancelText: t('common_phrases.abort'),
                                        cancelButtonColor: ButtonColors.DANGER,
                                        onProceedDialog: () => this.printRegistrationConfirmationLinkAsQRCode(customer),
                                    },
                                    title: t('customer_details.print_qr_code_title'),
                                    noButtons: true,
                                })
                            }>
                            <span className="glyphicon glyphicon-print text-info" />
                        </button>
                    </>
                )}
            </div>
        );
    }

    renderImportRatioCustomerButton() {
        const { t } = this.props;
        return (
            <button
                className="btn btn-xs btn-link nav-link pull-right"
                onClick={() => {
                    this.props.openModal({
                        component: CustomerAdministrationImportModal,
                        title: t('customer_details.import_customer'),
                        componentProps: {
                            fetchCustomers: this.fetchCustomers,
                            dialogStyle: {
                                width: '65%',
                                maxWidth: 'none',
                            },
                        },
                        successButtonLabel: t('customer_details.import_customer'),
                    });
                }}>
                <span className="glyphicon glyphicon-plus" />
                &nbsp;{t('customer_details.import_customer')}
            </button>
        );
    }
}

export default withTranslation()(composeModalActions(CustomerAdministration));
